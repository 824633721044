export const env = import.meta.env.MODE
export const projectId = import.meta.env.VITE_FIREBASE_PROJECT_ID
export const baseURL = import.meta.env.VITE_BASE_URL
export const hostName = window.location.hostname
const isEmulating = hostName === 'localhost'
export const isDevelopment = env === 'development' || isEmulating
export const databaseVersion = import.meta.env.VITE_DATABASE_VERSION
export const sentryDSN = import.meta.env.VITE_SENTRY_DSN
export const isStaging = !isDevelopment && projectId === 'huntre-dev'
export const serviceName = isStaging ? 'ワナップ(stg)' : 'ワナップ'
export const stripeConfig = {
  productId: {
    device: import.meta.env.VITE_STRIPE_DEVICE_PRODUCT_ID,
    simPlan: import.meta.env.VITE_STRIPE_SIM_PRODUCT_ID,
  },
}
export const corporateSiteUrl = import.meta.env.VITE_CORPORATE_SITE_URL ?? ''
export const registerFormUrl = import.meta.env.VITE_REGISTER_FORM_URL ?? ''
export const inquiryFormUrl = import.meta.env.VITE_INQUIRY_FORM_URL ?? ''
export const appCheckSecret = import.meta.env.VITE_FIREBASE_APP_CHECK_SECRET
export const supportEmail = import.meta.env.VITE_SUPPORT_EMAIL ?? ''
export const noreplyEmail = import.meta.env.VITE_NOREPLY_EMAIL ?? ''
export const loginEmail = import.meta.env.VITE_LOGIN_EMAIL ?? ''
export const supportPhone = import.meta.env.VITE_SUPPORT_PHONE ?? ''
export const lineId = import.meta.env.VITE_LINE_ID ?? ''

// docomo
// export const availableAreaUrl = https://www.docomo.ne.jp/area/servicearea/?rgcd=03&cmcd=LTE
// au
export const availableAreaUrl = 'https://www.au.com/mobile/area/map/?ope=au&plan=4glte'
