import { Paper, Stack, Typography } from '@mui/material'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { SimpleGrid, SimpleGridRow } from 'src/components/atoms/SimpleGrid'
import { baseURL, corporateSiteUrl, isStaging, serviceName, supportEmail, supportPhone } from 'src/config'

const rows: SimpleGridRow[] = [
  {
    head: '販売業者の名称',
    body: '株式会社Xenon',
  },

  {
    head: '所在地',
    body: '373-0062 群馬県太田市鳥山中町1598-1',
  },
  {
    head: '電話番号',
    body: (
      <Typography>
        {supportPhone}
        <br />
        受付時間 平日10:00 ~ 18:00
        <br />
        メールの方が確実です
      </Typography>
    ),
  },
  {
    head: 'メールアドレス',
    body: supportEmail,
  },
  {
    head: '運営統括責任者',
    body: '千田啄丸',
  },
  {
    head: '会社HP',
    body: (
      <Link to={corporateSiteUrl} target="_blank">
        {corporateSiteUrl}
      </Link>
    ),
  },
  {
    head: '追加手数料等の追加料金',
    body: '配送料 (一律1,000円/箱)',
  },
  {
    head: '商品以外の代金',
    body: '商品代金以外に配送料が掛かります。お客様のご都合による返品の場合は、返送料をご負担いただきます。',
  },
  {
    head: '交換および返品（返金ポリシー）',
    body: (
      <Stack spacing={2}>
        <Typography>
          お届け商品の返品をご希望の場合、商品到着後8日以内 (商品到着日を1日目として数えます)にお申し出ください。
          <br />
          商品に欠陥がない場合の返品、開封後、ご使用後の返品はお受けできませんのであらかじめご了承ください。
        </Typography>
        <Typography>
          [商品に不備がある場合]
          <br />
          当社の送料負担にて返金又は新しい商品と交換いたします。まずはお問合せページよりお問い合わせください。
        </Typography>
      </Stack>
    ),
  },
  {
    head: '引渡時期',
    body: 'ご注文確定後に10営業日以内に発送いたします。',
  },
  {
    head: '受け付け可能な決済手段',
    body: 'クレジットカードまたは、国内の銀行振込',
  },
  {
    head: '決済期間',
    body: (
      <Typography>
        クレジットカード: ご利用のクレジットカード契約の定めによります。
        <br />
        銀行振込: 商品注文後7日以内にお支払いください。
      </Typography>
    ),
  },
  {
    head: '販売価格',
    body: '各商品ページに記載しております。そちらをご確認ください。',
  },
]

export const TokuteishouTorihikihou = () => {
  return (
    <>
      <Helmet>
        {isStaging && <meta name="robots" content="noindex" />}
        <meta charSet="utf-8" />
        <title>{serviceName} | 特定商取引法に基づく表記</title>
        <meta property="og:type" content="product" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="description" content={`${serviceName} | 特定商取引法に基づく表記`} />
        <meta property="description" content={`${serviceName} | 特定商取引法に基づく表記`} />
        <meta property="og:url" content={`${baseURL}/public/tokuteishoutorihikihou`} />
        {/* SNS */}
        {/* TODO create icon */}
        <meta property="og:image" content={`${baseURL}/icon.png`} />
        {/* TODO end of create icon */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={serviceName} />
        <meta name="twitter:description" content={`${serviceName} | 特定商取引法に基づく表記`} />
        <meta name="twitter:image" content={`${baseURL}/icon.png`} />
      </Helmet>
      <Stack spacing={6}>
        <Typography variant="h5">特定商取引法に基づく表記</Typography>
        <Paper elevation={6} sx={{ p: 4 }}>
          <SimpleGrid rows={rows} alignItems="top" />
        </Paper>
      </Stack>
    </>
  )
}
