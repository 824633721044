import { ClientDeviceWithDevice } from '@maru44/huntre-utils/src/models/clientDevice'
import { ClientSubscription, ClientSubscriptionInvoice } from '@maru44/huntre-utils/src/models/clientSubscription'
import { CommunicationStatus, communicationStatus } from '@maru44/huntre-utils/src/models/communicationStatus'
import { calculateSimCancelExpiredAt, getSimFrozenAt, Sim, SimProvider } from '@maru44/huntre-utils/src/models/sim'
import { SimApplication } from '@maru44/huntre-utils/src/models/simApplication'
import { Box, Button, Typography } from '@mui/material'
import { isBefore } from 'date-fns'
import { FC, useContext, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { IconPopover } from 'src/components/atoms/IconPopover'
import { StatusChip } from 'src/components/atoms/StatusChip'
import { ClientContext } from 'src/components/providers/ClientProvider'
import { formatTimestampToDate, formatTimeToDate } from 'src/utils/firebase'

type Props = {
  clientId: string
  data: ClientDeviceWithDevice & { simApplication: SimApplication | null | undefined }
  sim: Sim | null
  clientSubscription: ClientSubscription | null
  invoices: ClientSubscriptionInvoice[] | null

  setOpenSimApplication: (open: boolean) => void
  setOpenCancelSubscription: (open: boolean) => void
}

export const Communication: FC<Props> = ({ clientId, data, sim, clientSubscription, invoices, setOpenSimApplication, setOpenCancelSubscription }) => {
  const { client } = useContext(ClientContext)
  const simStatus = communicationStatus(data.device, sim, data.simApplication)

  const cancelDeadline = sim?.expiresAt && calculateSimCancelExpiredAt(sim.expiresAt.toDate())

  const canEdit = useMemo(() => {
    if (data.device.payerClientId === client?.id) {
      return true
    }
    return false
  }, [data.device.payerClientId, client?.id])

  const canCancel = useMemo(() => {
    if (!canEdit || !cancelDeadline) {
      return false
    }
    return isBefore(new Date(), cancelDeadline)
  }, [cancelDeadline, canEdit])

  if (!simStatus) {
    return <>loading...</>
  }

  return (
    <Box display="flex" gap={1} alignItems="center">
      <StatusChip status={simStatus} />
      {simStatus === CommunicationStatus.Ok && sim?.provider === SimProvider.Soracom && (
        <Typography>{sim?.expiresAt ? `期限: ${formatTimestampToDate(sim.expiresAt)}` : '無期限'}</Typography>
      )}
      {sim?.provider === SimProvider.Once && (
        <IconPopover icon="?">
          <Typography>
            通信は一定の通信量が(概算10年間)ご利用いただけます。
            <br />
            現在通信の更新フォームを作成しております。万が一通信量が上限に達しましたらお問合せください。
          </Typography>
        </IconPopover>
      )}
      <CommunicationForm
        clientId={clientId}
        sim={sim}
        simStatus={simStatus}
        canCancel={canCancel}
        cancelDeadline={cancelDeadline}
        clientSubscription={clientSubscription}
        invoices={invoices}
        setOpenSimApplication={setOpenSimApplication}
        setOpenCancelSubscription={setOpenCancelSubscription}
      />
    </Box>
  )
}

type CommunicationFormProps = {
  clientId: string
  simStatus: CommunicationStatus
  sim: Sim | null
  cancelDeadline: Date | null | undefined
  canCancel: boolean
  clientSubscription: ClientSubscription | null
  invoices: ClientSubscriptionInvoice[] | null

  setOpenCancelSubscription: (open: boolean) => void
  setOpenSimApplication: (open: boolean) => void
}

const CommunicationForm: FC<CommunicationFormProps> = ({
  clientId,
  simStatus,
  sim,
  canCancel,
  clientSubscription,
  invoices,
  cancelDeadline,
  setOpenSimApplication,
  setOpenCancelSubscription,
}) => {
  if (sim?.provider === SimProvider.Once) {
    return null
  }
  switch (simStatus) {
    case CommunicationStatus.Ok: {
      switch (clientSubscription?.stripeSubscriptionCollectionMethod) {
        case 'charge_automatically': {
          if (cancelDeadline) {
            return (
              <>
                {sim &&
                  (canCancel ? (
                    <Button onClick={() => setOpenCancelSubscription(true)}>
                      {sim.cancelSubscriptionUpdate ? '通信の更新のキャンセルを取り消す' : '通信の更新をキャンセルする'}
                    </Button>
                  ) : sim.cancelSubscriptionUpdate ? (
                    <Typography>通信の更新がキャンセルされます</Typography>
                  ) : (
                    <Typography>通信は自動的に更新されます</Typography>
                  ))}
                <IconPopover icon="?">
                  {sim?.cancelSubscriptionUpdate ? (
                    <Typography>
                      期限が来たら自動的に更新されます。
                      <br />
                      <br />
                      更新キャンセル期限は
                      {`${formatTimeToDate(cancelDeadline)}`}です。
                    </Typography>
                  ) : (
                    <Typography>
                      期限が来たらキャンセルされます。
                      <br />
                      <br />
                      キャンセルの取り消し期限は
                      {`${formatTimeToDate(cancelDeadline)}`}です。
                    </Typography>
                  )}
                </IconPopover>
              </>
            )
          }
          // TODO impl no cancelDeadLine
          break
        }
        case 'send_invoice': {
          const latestInvoice = invoices?.[0]
          // 支払い済みの場合
          if (latestInvoice && !latestInvoice.stripePaid && latestInvoice.stripeHostedInvoiceUrl) {
            return (
              <>
                <Typography>
                  更新が必要です、
                  <Link to={latestInvoice.stripeHostedInvoiceUrl} target="_blank">
                    こちら
                  </Link>
                  の請求書をお支払いください。
                </Typography>
              </>
            )
          } else if (latestInvoice && latestInvoice.stripePaid) {
            return (
              <>
                <Typography>期限が切れる1週間前 ({formatTimestampToDate(clientSubscription.stripeSubscriptionPeriodEnd)}) に次の請求書を送付します</Typography>
              </>
            )
          }
          break
        }
        // TODO impl
        // stripeにない場合 (モニター等)はここにくる
        default: {
        }
      }

      break
    }
    case CommunicationStatus.Paused: {
      if (sim?.expiresAt) {
        /* TODO 申し込み済みならその情報を表示 */

        return (
          <>
            <Typography>
              通信の申し込みは
              <Button
                variant="text"
                onClick={() => {
                  setOpenSimApplication(true)
                }}
              >
                こちら
              </Button>
              から。
              <br />
              {`${formatTimeToDate(getSimFrozenAt(sim.expiresAt))}`}までに通信が再開しない場合はデバイスを凍結します。
            </Typography>
            <IconPopover icon="?">
              <Typography>凍結されたデバイスの再開は申請やデバイスの送付等が必要になります (有料)。</Typography>
            </IconPopover>
          </>
        )
      }
      break
    }
    case CommunicationStatus.Terminated: {
      //   if (sim?.expiresAt && isBefore(getSimFrozenAt(sim.expiresAt), new Date()))
      // 凍結済み
      return (
        <>
          <Typography>1年以上通信の契約がないので凍結されました</Typography>
          <IconPopover icon="?">
            <Typography>
              通信の再開を希望する場合、
              <Link to={`/clients/${clientId}/inquiries`}>お問い合わせ</Link>
              よりご連絡ください。
            </Typography>
          </IconPopover>
        </>
      )
    }
  }

  return null
}
