import { ClientDevice } from '@maru44/huntre-utils/src/models/clientDevice'
import { Device } from '@maru44/huntre-utils/src/models/device'

export const getPositionWithUpdatedAt = (device: Device, clientDevice: ClientDevice) => {
  let position = device.position
  let positionUpdatedAt = device.positionUpdatedAt
  if (clientDevice.isInputPositionUsed) {
    position = clientDevice.position ?? null
    positionUpdatedAt = clientDevice.positionUpdatedAt ?? null
  }
  return { position, positionUpdatedAt }
}
