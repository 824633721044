import { ClientDeviceWithDevice } from '@maru44/huntre-utils/src/models/clientDevice'
import { ClientMember, ClientMemberRole } from '@maru44/huntre-utils/src/models/clientMember'
import { CommunicationStatus, communicationStatus } from '@maru44/huntre-utils/src/models/communicationStatus'
import { IsTrappingStatus } from '@maru44/huntre-utils/src/models/device'
import { DeviceType } from '@maru44/huntre-utils/src/models/deviceType'
import { calculateSimCancelExpiredAt, Sim, SimProvider } from '@maru44/huntre-utils/src/models/sim'
import { SimApplication } from '@maru44/huntre-utils/src/models/simApplication'
import { Box, Typography } from '@mui/material'
import { flush } from '@sentry/react'
import { isBefore } from 'date-fns'
import { useCallback, useContext, useMemo, useState } from 'react'
import { toast } from 'react-hot-toast'
import { IconPopover } from 'src/components/atoms/IconPopover'
import { SimpleGrid, SimpleGridRow } from 'src/components/atoms/SimpleGrid'
import { StatusChip } from 'src/components/atoms/StatusChip'
import { ClientDeviceSwitch } from 'src/components/dialogs/device/ClientDeviceSwitch'
import { AuthContext } from 'src/components/providers/AuthProvider'
import { ClientContext } from 'src/components/providers/ClientProvider'
import { SimPlanWithPrice } from 'src/components/providers/GlobalProductProvider'
import { useClientDevice } from 'src/hooks/clientDevice/useClientDevice'
import { formatTimestamp } from 'src/utils/firebase'
import { capture } from 'src/utils/sentry'
import { KeyedMutator } from 'swr'
import { PopoverWhatIsTurnedOff } from '../../../components/device/PopoverIsTurnedOff'
import { ApplySimPlanForm } from './ApplySimPlanForm'
import { CancelSubscriptionUpdate } from './CancelSubscriptionUpdate'
import { Communication } from './Communication'

type ClientDeviceDetailProps = {
  clientId: string
  data: ClientDeviceWithDevice & { simApplication: SimApplication | null | undefined }
  deviceType: DeviceType | undefined
  simPlans: SimPlanWithPrice[] | undefined
  sim: Sim | null
  currentMember: ClientMember | undefined
  mutate: KeyedMutator<ClientDeviceWithDevice & { simApplication: SimApplication | null }>
  mutateSim: KeyedMutator<Sim>
}

export const ClientDeviceDetail = ({ clientId, data, deviceType, simPlans, sim, currentMember, mutate, mutateSim }: ClientDeviceDetailProps) => {
  const { client } = useContext(ClientContext)
  const [user] = useContext(AuthContext)
  const { updateIsTurnedOff } = useClientDevice(data.device.id, clientId)
  const [openSimApplication, setOpenSimApplication] = useState<boolean>(false)

  const [openCancelSubscription, setOpenCancelSubscription] = useState<boolean>(false)

  const isTurnedOff = useMemo(() => {
    return data.clientDevice.isTurnedOff
  }, [data])

  const handleChangeIsTurnedOff = useCallback(async () => {
    try {
      await updateIsTurnedOff(!isTurnedOff)
    } catch (e) {
      capture(e)
      await flush(100)
      toast.error('通知の変更に失敗しました')
      return
    }
    toast.success('通知の値を変更しました')
    await mutate()
  }, [isTurnedOff, clientId, mutate, updateIsTurnedOff])

  const trappingStatus = useMemo((): IsTrappingStatus => {
    if (data.device.isTrapping) return IsTrappingStatus.Trapping
    return IsTrappingStatus.NotTrapping
  }, [data.device.isTrapping])

  const canEdit = useMemo(() => {
    if (data.device.payerClientId === client?.id) {
      return true
    }
    return false
  }, [data.device.payerClientId, client?.id])

  const cancelDeadline = sim?.expiresAt && calculateSimCancelExpiredAt(sim.expiresAt.toDate())
  const canCancel = useMemo(() => {
    if (!canEdit || !cancelDeadline) {
      return false
    }
    return isBefore(new Date(), cancelDeadline)
  }, [cancelDeadline, canEdit])

  const simStatus = communicationStatus(data.device, sim, data.simApplication)

  const rows = useMemo(
    (): SimpleGridRow[] => [
      {
        head: 'デバイスID',
        body: data.device.id,
      },
      ...(!data.clientDevice.isTurnedOff
        ? [
            {
              head: (
                <Box display="flex" alignItems="center" gap={1}>
                  <Typography>罠状況</Typography>
                  <IconPopover icon="?">
                    <Typography>磁石が外れていると作動、ついていると未作動となります</Typography>
                  </IconPopover>
                </Box>
              ),
              body: <StatusChip status={trappingStatus} />,
            },
          ]
        : []),
      ...(data.device.isTrapping && !!data.device.trappedAt
        ? [
            {
              head: '捕獲日時',
              body: formatTimestamp(data.device.trappedAt),
            },
          ]
        : []),
      // ...(!!data.device.lastTurnedOnAt && !data.clientDevice.isTurnedOff
      //   ? [
      //       {
      //         head: '通知on日時',
      //         body: formatTimestamp(data.device.lastTurnedOnAt),
      //       },
      //     ]
      //   : []),
      {
        head: '最終通信日時',
        body: data.device.lastMessageReceivedAt ? formatTimestamp(data.device.lastMessageReceivedAt) : null,
      },
      ...(!data.clientDevice.isTurnedOff
        ? [
            {
              head: '異常検知',
              body: <StatusChip status={data.device.operationStatus} />,
            },
          ]
        : []),
      {
        head: '通信状況',
        body: (
          <Communication
            clientId={clientId}
            sim={sim}
            data={data}
            clientSubscription={null}
            invoices={[]}
            setOpenCancelSubscription={setOpenCancelSubscription}
            setOpenSimApplication={setOpenSimApplication}
          />
        ),
      },
      // TODO del
      // {
      //   head: simStatus === 'ok' ? '通信延長申し込み' : '通信申し込み',
      //   body: (
      //     <SimApplicationStatus
      //       simPlan={simPlans?.find((v) => v.id === data.simApplication?.simPlanId)}
      //       device={data.device}
      //       sim={sim}
      //       deviceType={deviceType}
      //       simApplication={data.simApplication}
      //     />
      //   ),
      // },
      // {
      //   head: '登録日時',
      //   body: formatTimestamp(data.clientDevice.createdAt),
      // },
      {
        head: <Typography variant="subtitle2">ユーザー設定</Typography>,
      },
      {
        head: (
          <Box display="flex" alignItems="center" gap={1}>
            <Typography>通知</Typography>
            <PopoverWhatIsTurnedOff />
          </Box>
        ),
        body: (
          <ClientDeviceSwitch clientDevice={data.clientDevice} onUpdate={handleChangeIsTurnedOff} disabled={currentMember?.role !== ClientMemberRole.Admin} />
        ),
      },
    ],
    [data, handleChangeIsTurnedOff]
  )

  return (
    <Box>
      <SimpleGrid rows={rows} mt={2} height={4} alignItems="top" />
      {canEdit && sim?.provider === SimProvider.Soracom && data.device.simId && simPlans && simStatus === CommunicationStatus.Paused && (
        <ApplySimPlanForm
          open={openSimApplication}
          deviceId={data.device.id}
          simId={data.device.simId}
          simPlans={simPlans}
          onClose={() => setOpenSimApplication(false)}
        />
      )}
      {canCancel && sim?.provider === SimProvider.Soracom && sim && sim.clientSubscriptionId && (
        <CancelSubscriptionUpdate
          open={openCancelSubscription}
          deviceId={data.device.id}
          sim={sim}
          onClose={() => setOpenCancelSubscription(false)}
          mutate={mutateSim}
        />
      )}
    </Box>
  )
}

// type SimApplicationStatusProps = {
//   device: Device
//   deviceType: DeviceType | undefined
//   sim: Sim | null | undefined
//   simApplication: SimApplication | null | undefined
//   simPlan: SimPlan | undefined
// }

// const SimApplicationStatus = ({ device, deviceType, sim, simApplication, simPlan }: SimApplicationStatusProps) => {
//   if (simApplication === undefined || deviceType === undefined) {
//     return null
//   }
//   if (!deviceType.requiredSim) {
//     return null
//   }

//   const status = communicationStatus(device, sim, simApplication)

//   if (!status) {
//     return null
//   }

//   if (status === 'booked') {
//     return (
//       <Box display="flex" justifyContent="space-between" alignItems="center">
//         <Box display="flex" gap={1} alignItems="center">
//           {simPlan && <Typography>{simPlan.displayName}</Typography>}
//           <Typography>{`開始日: ${simApplication?.startedAt && formatTimestampToDate(simApplication.startedAt)}`}</Typography>
//         </Box>
//         <IconButton color="inherit">
//           <IoSettingsSharp />
//         </IconButton>
//       </Box>
//     )
//   }

//   if (status === 'ok' && simApplication) {
//     return (
//       <Box display="flex" justifyContent="space-between" alignItems="center">
//         <Box display="flex" gap={1} alignItems="center">
//           {simPlan && <Typography>{simPlan.displayName}</Typography>}
//           <Typography>{`延長開始日: ${simApplication.startedAt && formatTimestampToDate(simApplication.startedAt)}`}</Typography>
//         </Box>
//         <IconButton color="inherit">
//           <IoSettingsSharp />
//         </IconButton>
//       </Box>
//     )
//   }

//   if (status === 'ok') {
//     return (
//       <Box display="flex" justifyContent="space-between" alignItems="center">
//         <Box display="flex" gap={1} alignItems="center">
//           {simPlan && <Typography>{simPlan.displayName}</Typography>}
//           <Typography component={Button}>延長申請</Typography>
//         </Box>
//       </Box>
//     )
//   }

//   // TODO 分ける
//   if (status === 'terminated' || status === 'noSim') {
//     return (
//       <Box display="flex" alignItems="center" justifyContent="space-between">
//         <Typography color={(theme) => theme.palette.error.main}>
//           通信が凍結されています。このままではご利用いただけません。{/* TODO Link */}こちらより通信の再開申請を行なってください。
//           <br />
//           通信の期限が切れてから1年以上経ったデバイスや不正利用が疑われるデバイス等が凍結対象となっております。
//         </Typography>
//       </Box>
//     )
//   }

//   return (
//     <Box display="flex" gap={1} alignItems="center">
//       <StatusChip status={status} />
//       <Button>通信を申し込む</Button>
//     </Box>
//   )
// }
