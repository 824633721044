import { zodResolver } from '@hookform/resolvers/zod'
import { Box, Button, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material'
import { FormEvent, useCallback, useContext } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { PopoverWhatIsTurnedOff } from 'src/components/device/PopoverIsTurnedOff'
import { LoadingLayout } from 'src/components/layouts/LoadingLayout'
import { AuthContext } from 'src/components/providers/AuthProvider'
import { ClientContext } from 'src/components/providers/ClientProvider'
import { useClients } from 'src/hooks/client/useClients'
import { useClientDeviceWithClientId } from 'src/hooks/clientDevice/useClientDeviceWithClientId'
import { DuplicateError } from 'src/utils/error'
import { capture } from 'src/utils/sentry'
import useSWR from 'swr'
import { z } from 'zod'
import { ErrorPage } from '../ErrorPage'

type Props = {
  id: string
}

const schema = z.object({
  clientId: z.string(),
  isTurnedOff: z.string(),
})
type FormState = z.infer<typeof schema>

export const Register = ({ id }: Props) => {
  const { client: defaultClient } = useContext(ClientContext)
  const [user] = useContext(AuthContext)
  const { listClients } = useClients(user?.uid ?? '')
  const clients = useSWR('clients', user?.uid ? () => listClients() : null)

  const { getClientDevice, createClientDevice } = useClientDeviceWithClientId(id)

  const { control, handleSubmit, formState, setValue, watch } = useForm<FormState>({
    resolver: zodResolver(schema),
    defaultValues: {
      clientId: defaultClient?.id ?? '',
      isTurnedOff: 'false',
    },
    mode: 'all',
  })

  const navigate = useNavigate()

  const submit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault()
      const current = watch()
      try {
        await handleSubmit(async (input) => {
          await createClientDevice(input.clientId, input.isTurnedOff === 'true')
        })()
        toast.success(`デバイス(${id})を登録しました`)
        navigate(`/clients/${current.clientId}/devices/${id}`)
      } catch (e) {
        if (e instanceof DuplicateError) {
          // 既に登録してあれば単純にnavigate
          await getClientDevice(current.clientId)
          toast.success(`デバイス (${id})は既に登録されています`)
          navigate(`/clients/${current.clientId}/devices/${id}`)
          return
        }
        capture(e)
      }
    },
    [handleSubmit, createClientDevice, navigate, watch]
  )

  if (clients.isLoading) {
    return <LoadingLayout />
  }

  if (clients.error) {
    return <ErrorPage exception={clients.error} />
  }

  return (
    <Box>
      <Typography variant="h5">デバイス({id})の登録</Typography>
      <Stack component="form" spacing={2} onSubmit={submit} mt={4}>
        <Controller
          name="clientId"
          control={control}
          defaultValue={defaultClient?.id ?? ''}
          render={({ field }) => (
            <>
              <InputLabel>グループ</InputLabel>
              <Select
                fullWidth
                {...field}
                onChange={(e) => {
                  setValue('clientId', e.target.value)
                }}
                error={!!formState.errors?.clientId}
              >
                {clients.data?.map((v) => (
                  <MenuItem value={v.id} key={v.id}>
                    {v.name}
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
        />
        <Controller
          name="isTurnedOff"
          control={control}
          render={({ field }) => (
            <>
              <Box display="flex" alignItems="center" gap={1}>
                <InputLabel>通知</InputLabel>
                <PopoverWhatIsTurnedOff />
              </Box>
              <Select
                fullWidth
                {...field}
                onChange={(e) => {
                  setValue('isTurnedOff', e.target.value)
                }}
                error={!!formState.errors?.isTurnedOff}
              >
                <MenuItem value="true">通知off</MenuItem>
                <MenuItem value="false">通知on</MenuItem>
              </Select>
            </>
          )}
        />
        <Box display="flex" justifyContent="end" alignItems="center">
          <Button variant="contained" type="submit" disabled={!formState.isValid || formState.isSubmitting}>
            デバイスを登録
          </Button>
        </Box>
      </Stack>
    </Box>
  )
}
