import { Box, Button, InputLabel, Stack, TextField, Typography } from '@mui/material'
import { FormEvent, useCallback, useState } from 'react'
import GoogleButton from 'react-google-button'
import { toast } from 'react-hot-toast'
import { loginEmail } from 'src/config'
import { useAuth } from 'src/hooks/useAuth'

export const LoginPage = () => {
  const [email, setEmail] = useState<string>('')
  const { sendAuthEmail, googleLogin } = useAuth()

  const handleClick = useCallback(
    async (e: FormEvent) => {
      try {
        e.preventDefault()
        if (!email) {
          toast.error('メールアドレスを入力してください')
          return
        }
        await sendAuthEmail(email)
        toast.success('メールを送信しました')
        setEmail('')
      } catch (e) {
        toast.error('メールの送信に失敗しました')
        setEmail('')
      }
    },
    [email, sendAuthEmail]
  )

  return (
    <Stack spacing={4}>
      <Typography variant="h5">ログイン</Typography>
      <Stack spacing={2} component="form" onSubmit={handleClick}>
        <Typography variant="h6">ログインリンク認証</Typography>
        <Typography>
          ログインリンクをメールアドレスに送信します。
          <br />
          送られたリンクをクリックするとログインできます。
          <br />
          <br />
          {loginEmail}からメールが送信されます
        </Typography>
        <InputLabel>メールアドレス</InputLabel>
        <TextField
          fullWidth
          name="email"
          type="email"
          required
          placeholder="example@example.com"
          onChange={(e) => {
            e.preventDefault()
            setEmail(e.currentTarget.value)
          }}
          value={email}
        />
        <Box display="flex" justifyContent="end" alignItems="center">
          <Button type="submit" variant="contained">
            送信
          </Button>
        </Box>
      </Stack>
      <Stack spacing={2}>
        <Typography variant="h6">OAuth</Typography>
        <Box>
          <GoogleButton
            onClick={async () => {
              await googleLogin()
            }}
          />
        </Box>
      </Stack>
    </Stack>
  )
}
