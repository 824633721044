import { doc, getDoc, serverTimestamp, setDoc } from '@firebase/firestore'
import { ClientDevice } from '@maru44/huntre-utils/src/models/clientDevice'
import { getData } from '@maru44/huntre-utils/src/utils/data'
import { useCallback } from 'react'
import { DuplicateError, NotFoundError } from 'src/utils/error'
import { firestore } from 'src/utils/firebase'

export const useClientDeviceWithClientId = (id: string) => {
  const getClientDevice = useCallback(
    async (clientId: string) => {
      const cdRef = doc(firestore, `clients/${clientId}/clientDevices/${id}`)
      const snap = await getDoc(cdRef)
      const cd = getData<ClientDevice>(snap)

      if (!cd) {
        throw new NotFoundError('clientDevice', id)
      }
      return cd
    },
    [id]
  )

  const createClientDevice = useCallback(
    async (clientId: string, isTurnedOff?: boolean) => {
      const clientDeviceDocRef = doc(firestore, `clients/${clientId}/clientDevices/${id}`)
      const clientDeviceDoc = await getDoc(clientDeviceDocRef)
      if (clientDeviceDoc.exists()) {
        throw new DuplicateError('clientDevice', { key: 'id', value: id })
      }

      const now = serverTimestamp()
      await setDoc(clientDeviceDocRef, {
        deviceId: id,
        isTurnedOff: isTurnedOff === undefined ? false : isTurnedOff,
        createdAt: now,
        updatedAt: now,
      })
    },
    [id]
  )

  return { getClientDevice, createClientDevice }
}
