import { Box, colors, Typography } from '@mui/material'
import { Helmet } from 'react-helmet'
import { baseURL, inquiryFormUrl, isStaging, serviceName } from 'src/config'

export const Inquiry = () => {
  return (
    <>
      <Helmet>
        {isStaging && <meta name="robots" content="noindex" />}
        <meta charSet="utf-8" />
        <title>{serviceName} | お問合せ</title>
        <meta property="og:type" content="product" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="description" content={`${serviceName} | お問合せはこちらから`} />
        <meta property="description" content={`${serviceName} | お問合せはこちらから`} />
        <meta property="og:url" content={`${baseURL}/inquiry`} />
        {/* SNS */}
        {/* TODO create icon */}
        <meta property="og:image" content={`${baseURL}/icon.png`} />
        {/* TODO end of create icon */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={serviceName} />
        <meta name="twitter:description" content={`${serviceName} | お問合せはこちらから`} />
        <meta name="twitter:image" content={`${baseURL}/icon.png`} />
      </Helmet>
      <>
        <Typography variant="h5">お問合せ</Typography>
        <Typography mt={6}>ワナップに関するお問い合わせはこちらからお願いします。</Typography>
        <Box mt={6}>
          <iframe
            src={inquiryFormUrl}
            width="100%"
            height="1280px"
            // frameborder="0"
            // marginheight="0"
            // marginwidth="0"
            loading="lazy"
            style={{ border: 'none', borderRadius: '4px', backgroundColor: colors.grey[100] }}
          >
            読み込んでいます…
          </iframe>
        </Box>
      </>
    </>
  )
}
