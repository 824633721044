import { Box, Button, colors, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import { availableAreaUrl, serviceName } from 'src/config'

export const LP = () => {
  return (
    <Stack spacing={12} mt={4}>
      <Box>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Typography variant="h3">{serviceName}</Typography>
        </Box>
        <Box mt={3} width="100%" display="flex" alignItems="center" justifyContent="center" position="relative">
          <img src="/device_top.jpg" width="100%" />
          <Button
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              '-ms-transform': 'translate(-50%,-50%)',
              '-webkit-transform': 'translate(-50%,-50%)',
              transform: 'translate(-50%,-50%)',
              boxShadow: `-3px -3px 6px 0px ${colors.grey[300]},0px 3px 10px 0px ${colors.grey[700]}`,
            }}
            component={Link}
            variant="contained"
            to="/inquiry/register"
          >
            ご利用申請
          </Button>
        </Box>
      </Box>

      <Box>
        <Typography variant="h5">IoT罠とその管理サービス</Typography>
        <Typography mt={3}>
          {serviceName}とはIoT罠デバイスとその管理Webアプリです。
          <br />
          罠の見回りの負担を大幅に軽減します。
          <br />
          使いやすく、価格も非常にお求め安い価格としております。
          <br />
          <br />
          本サービスは弊社社長自身が新米ハンターとして既存のIoT罠が高すぎると感じ、それなら自分で作ってしまおうという形で始まりました。見回りの負担の軽減により新米ハンターの定着や、熟練のハンターを支援し狩猟をより盛り上げていきます。
          <br />
          本当に必要な最低限の機能だけを実装し、その分お安くしております。
          <br />
          <br />
          <Link to="/inquiry/register">こちら</Link>のリンクからご利用申請いただけます。
        </Typography>
        <Box mt={6}>
          <iframe
            src="https://docs.google.com/presentation/d/e/2PACX-1vRl8d3gsuOWXBNK-gwmDHBSJWIkqMg_-XxWA3y0dtxYB3z-E5C-hvuqyp-bWRnxuckyUFj_rbH5-ZE_/embed?start=false&loop=false&delayms=3000"
            width="100%"
            style={{ aspectRatio: 16 / 9, border: 0 }}
            allowFullScreen
            loading="lazy"
          />
        </Box>
        {/* TODO add image of device */}
      </Box>
      <Box>
        <Typography variant="h5">Webアプリ</Typography>
        <Stack spacing={3} mt={3} direction={{ xs: 'column', md: 'row' }}>
          <Box>
            <Typography>Webアプリは無料でご利用いただけます。</Typography>
            {/* TODO add 申し込みフォームへの導線 */}
            <Typography>Webアプリからデバイスの購入、デバイスの一元管理ができます。</Typography>
            <Typography>グループの管理やグループでのデバイスの共有も簡単にできるので個人のみならず自治体等でのご利用にも最適です。</Typography>
          </Box>
          <Box width="100%">
            <Paper sx={{ backgroundColor: '#f3f3f3' }}>
              <Box p={1} justifyContent="space-between" display="flex" alignItems="flex-start">
                {/* <img width="49%" src="/device_list.png" />
              <img width="49%" src="/device_detail.png" /> */}
                <img width="100%" src="device_list.png" />
              </Box>
              <Typography variant="caption" mt={1}>
                ※画像は開発時のものです
              </Typography>
            </Paper>
          </Box>
        </Stack>
      </Box>
      <Box>
        <Typography variant="h5">通知機能</Typography>
        <Stack spacing={3} mt={3} direction={{ xs: 'column-reverse', md: 'row' }}>
          <Box>
            <img width="100%" src="notification_image.png" />
          </Box>
          <Box>
            <Typography>
              捕獲通知のみならず、故障や電池切れの可能性のあるデバイスを検知して通知します。
              <br />
              現在メール、管理画面、LINEへの通知に対応しております。ご要望次第でSMS等チャネルを増やす予定です。
            </Typography>
          </Box>
        </Stack>
      </Box>
      <Box>
        <Typography variant="h5">デバイス種別</Typography>
        <Box mt={3}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell width="30%"></TableCell>
                  <TableCell width="35%" align="center">
                    <b>LTEモデル</b>
                  </TableCell>
                  <TableCell width="35%" align="center">
                    <b>Coming soon...</b>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell component="th"></TableCell>
                  <TableCell align="center">
                    <img src="/wanapp_device_image.png" height="200px" alt="LTEモデルのイメージ" />
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th">料金</TableCell>
                  <TableCell align="center">22,000円 (税込)</TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th">通信費</TableCell>
                  <TableCell align="center">
                    無料
                    <br />
                    デバイス本体価格に含まれます
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th">通信方法</TableCell>
                  <TableCell align="center">
                    LTE-M
                    <br />
                    利用可能エリアは
                    <Link to={availableAreaUrl} target="_blank">
                      こちら
                    </Link>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th">親機や中継機等</TableCell>
                  <TableCell align="center">不要</TableCell>
                  <TableCell></TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th">詳細</TableCell>
                  <TableCell align="center">
                    <Link to="/deviceTypes/lte-model-0001">デバイスの詳細はこちら</Link>
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      {/* <Box>
        <Typography variant="h5">お客様の声</Typography>
        <ul style={{ listStyleType: 'disc' }}>
          <li style={{ display: 'list-item' }}>見回りを減らすことができてよかった</li>
          <li style={{ display: 'list-item' }}>止め刺しの準備 (心の準備を含む)が前もってできる</li>
          <li style={{ display: 'list-item' }}>早く対応出来るので、獲物の傷みも少ない。同じく、罠の傷みも少ない</li>
          <li style={{ display: 'list-item' }}>電池切れも通知してくれるのでデバイスがちゃんと動いているか不安になる必要がない</li>
        </ul>
      </Box> */}
      <Box>
        <Typography variant="h5">ワナップのストーリー</Typography>
        <Stack mt={3} spacing={2}>
          <Box>
            <Typography variant="h6">ワナップを作ろうと思った理由</Typography>
            <Typography>
              ワナップは会社代表が猟師として活動する中で生まれました。
              会社員として仕事をしながら罠猟を行っていく中で、仕事をしながらの毎日の罠の見回りが非常に大変だということに気づき、見回りを2日に1度に減らすべくIoT罠の購入等を検討しました。しかし既存のデバイス・サービスは非常に料金が高額かつあまり使いやすそうではなかったです。そこで、それなら自分で作ってしまえ！という考えで開発が始まりました。自らのエンジニアとしてのスキル、猟師としてのニーズを融合し、安くて使いやすいをとことん追求したIoT罠のデバイスとデバイス管理サービスを提供しています。
            </Typography>
          </Box>
          <Box>
            <Typography variant="h6">ワナップが目指すこと</Typography>
            <Typography>
              獣害による農業被害額は今や年に156億円(2022年調べ)と言われています。また、2023年は暖冬などの影響もあり熊の出没や人身への被害も多発しました。
              一方彼ら獣たちと私たちの領域を明確にするために尽力している猟師は年々高齢化してきています。若くても簡単ではない毎日の見回りは高齢となった猟師にとって負担であると考えています。
              また、現役世代にとって仕事をしながらの毎日の見回りの負担は軽くはなく、初心者の猟師の狩猟の機会や挑戦を奪っていると考えられます。
              安価で使いやすいワナップが行政、ベテランハンター、初心者ハンターの手に行き渡り、彼らの負担を大幅に軽減することで農業被害や森林被害を減らすことに貢献し、最終的には鳥獣と人間との真の共存を目指しています。
            </Typography>
          </Box>
        </Stack>
      </Box>
      <Box>
        <Typography variant="h5">ご紹介いただきました</Typography>
        <Stack spacing={4} mt={3}>
          <Link to="https://note.com/syuryo_seikatsu/n/n96b81196bc98" target="_blank" style={{ textDecoration: 'none' }}>
            <Paper elevation={6} sx={{ padding: '20px 10px' }}>
              <Stack spacing={2}>
                <Typography variant="h6">罠の作動をアプリで通知「ワナップ」で見回り効率化</Typography>
                <img
                  src="https://assets.st-note.com/production/uploads/images/171136485/rectangle_large_type_2_b0b99395c1cbbf90fcb433913a556ba2.png"
                  style={{ maxWidth: '620px' }}
                  width="100%"
                />
                <Typography variant="subtitle1">狩猟生活 (山と渓谷社さま)</Typography>
              </Stack>
            </Paper>
          </Link>
          <Paper elevation={6} sx={{ padding: '20px 10px' }}>
            <Stack spacing={2}>
              <Box sx={{ position: 'relative', paddingBottom: 'min(56.25%, 315px)', height: 0, overflow: 'hidden', maxWidth: '560px' }}>
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/bOzOi1Bky1w?si=CutIwQlYRyaJa3uq"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                  loading="lazy"
                  style={{ position: 'absolute', top: 0, left: 0 }}
                />
              </Box>
              <Typography variant="subtitle1">lifeさま</Typography>
            </Stack>
          </Paper>
          <Paper elevation={6} sx={{ padding: '20px 10px' }}>
            <Stack spacing={2}>
              <Box sx={{ position: 'relative', paddingBottom: 'min(56.25%, 315px)', height: 0, overflow: 'hidden', maxWidth: '560px' }}>
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/jWcNlN7g7VA?si=QwvWsm5vx3_SW17k"
                  title="YouTube video player"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerPolicy="strict-origin-when-cross-origin"
                  allowFullScreen
                  loading="lazy"
                  style={{ position: 'absolute', top: 0, left: 0 }}
                />
              </Box>
              <Typography variant="subtitle1">わな猟師せきはんさま</Typography>
            </Stack>
          </Paper>
        </Stack>
      </Box>
      <Box>
        <Typography>さあ、ワナップを手に取って快適に狩猟・捕獲をしましょう！</Typography>
        <Box mt={3} width="100%" display="flex" alignItems="center" justifyContent="center" position="relative">
          <img src="/kukuri_wana.jpg" width="100%" />
          <Button
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              '-ms-transform': 'translate(-50%,-50%)',
              '-webkit-transform': 'translate(-50%,-50%)',
              transform: 'translate(-50%,-50%)',
              boxShadow: `-3px -3px 6px 0px ${colors.grey[300]},0px 3px 10px 0px ${colors.grey[700]}`,
            }}
            component={Link}
            variant="contained"
            to="/inquiry/register"
          >
            ご利用申請
          </Button>
        </Box>
      </Box>

      {/* <Box>
        <Typography variant="h5">使いやすい管理画面</Typography>
        <Typography mt={1}>
          管理画面でデバイスの情報を一目で確認できます。
          <br />
          グループの管理やグループでのデバイスの共有も簡単にできるので個人のみならず自治体等でのご利用にも最適です。
        </Typography>
        <Box p={1}>
          <Paper sx={{ backgroundColor: '#f3f3f3' }}>
            <Box p={1} justifyContent="space-between" display="flex" alignItems="flex-start">
              <img width="49%" src="/device_list.png" />
              <img width="49%" src="/device_detail.png" />
            </Box>
            <Typography variant="caption" mt={1}>
              ※画像は開発時のものです
            </Typography>
          </Paper>
        </Box>
      </Box> */}
    </Stack>
  )
}
