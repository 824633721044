import { ClientDevice } from '@maru44/huntre-utils/src/models/clientDevice'
import { ClientDeviceMemo } from '@maru44/huntre-utils/src/models/clientDeviceMemo'
import { ClientMember, ClientMemberRole } from '@maru44/huntre-utils/src/models/clientMember'
import { Device } from '@maru44/huntre-utils/src/models/device'
import { SimApplication } from '@maru44/huntre-utils/src/models/simApplication'
import { Avatar, Box, Button, Icon, IconButton, Paper, Stack, TextField, Typography } from '@mui/material'
import { useCallback, useContext, useMemo, useState } from 'react'
import { toast } from 'react-hot-toast'
import { IoNotifications, IoTrashSharp } from 'react-icons/io5'
import { BaseDialog } from 'src/components/atoms/BaseDialog'
import { IconPopover } from 'src/components/atoms/IconPopover'
import { AuthContext } from 'src/components/providers/AuthProvider'
import { baseURL } from 'src/config'
import { useClientDevice } from 'src/hooks/clientDevice/useClientDevice'
import { useClientDeviceMemos } from 'src/hooks/clientDeviceMemo/useClientDeviceMemos'
import { formatTimestamp } from 'src/utils/firebase'
import { capture } from 'src/utils/sentry'
import { KeyedMutator } from 'swr'

type Props = {
  memos: ClientDeviceMemo[] | undefined
  currentMember: ClientMember | undefined
  members: ClientMember[]
  clientId: string
  deviceId: string
  clientDevice: ClientDevice | undefined
  mutateMemos: KeyedMutator<ClientDeviceMemo[]>
  mutateDevice: KeyedMutator<{
    device: Device
    clientDevice: ClientDevice
    simApplication: SimApplication | null
  }>
}

export const Memos = ({ memos, currentMember, clientId, deviceId, clientDevice, members, mutateMemos, mutateDevice }: Props) => {
  const [user] = useContext(AuthContext)
  const { createDeviceMemo, deleteDeviceMemo } = useClientDeviceMemos(clientId, deviceId)
  const { updateMemoIdForNotification } = useClientDevice(deviceId, clientId)

  const author = useMemo(() => members.find((v) => v.id === user?.uid), [members, user])
  const [mem, setMem] = useState('')
  const [memoDeleted, setMemoDeleted] = useState<ClientDeviceMemo | undefined>()
  const [memoIdForNotification, setMemoIdForNotification] = useState<string | undefined>()

  const handleSubmit = useCallback(async () => {
    if (!author || !mem) {
      return
    }
    try {
      await createDeviceMemo(author, mem)
      setMem('')
      await mutateMemos()

      toast.success('メモを入力しました')
    } catch (e) {
      capture(e)
      toast.error('メモの作成に失敗しました')
    }
  }, [mem, author, createDeviceMemo, mutateMemos])

  const handleDelete = useCallback(async () => {
    if (!memoDeleted) {
      return
    }
    try {
      // clientDevicesに保存してある送付するmemo設定を消す
      if (clientDevice?.memoIdForNotification === memoDeleted.id) {
        await updateMemoIdForNotification(undefined)
      }
      await deleteDeviceMemo(memoDeleted.id)
      setMemoDeleted(undefined)
      await mutateMemos()

      toast.success('メモを削除しました')
    } catch (e) {
      capture(e)
      toast.error('メモを削除に失敗しました')
    }
  }, [clientDevice?.memoIdForNotification, memoDeleted, deleteDeviceMemo, mutateMemos, updateMemoIdForNotification])

  const handleUpdateMemoIdForNotification = useCallback(async () => {
    try {
      await updateMemoIdForNotification(memoIdForNotification)
      setMemoIdForNotification(undefined)
      toast.success('通知に送るメモを設定しました')
      await mutateDevice()
    } catch (e) {
      capture(e)
      toast.error('通知に送るメモを設定に失敗しました')
      setMemoIdForNotification(undefined)
    }
  }, [memoIdForNotification, mutateDevice, setMemoIdForNotification, updateMemoIdForNotification])

  return (
    <>
      <Stack spacing={2}>
        <Stack>
          {memos?.map((memo) => {
            const member = members.find((v) => v.id === memo.authorId)
            return (
              <Box>
                <Paper sx={{ p: 2 }}>
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Box display="flex" alignItems="center" gap={1} mb={1}>
                      <Avatar src={member?.iconURL ?? `${baseURL}/user.png`} sx={{ width: 18, height: 18 }} />
                      <Typography variant="subtitle1">{member?.name ?? memo.authorName}</Typography>
                    </Box>
                    {(currentMember?.role === ClientMemberRole.Admin || currentMember?.id === memo.authorId) && (
                      <Box display="flex" alignItems="center" gap={4}>
                        {clientDevice?.memoIdForNotification === memo.id ? (
                          <IconPopover
                            icon={
                              <Icon color="success">
                                <IoNotifications />
                              </Icon>
                            }
                          >
                            このメモは通知と一緒に送信されます
                          </IconPopover>
                        ) : (
                          <IconPopover
                            icon={
                              <IconButton onClick={() => setMemoIdForNotification(memo.id)}>
                                <IoNotifications />
                              </IconButton>
                            }
                          >
                            メモを通知と一緒に送付する
                          </IconPopover>
                        )}
                        <IconButton onClick={() => setMemoDeleted(memo)}>
                          <IoTrashSharp size={18} />
                        </IconButton>
                      </Box>
                    )}
                  </Box>
                  <Box>
                    <Typography variant="body1" whiteSpace="pre-wrap">
                      {memo.content}
                    </Typography>
                  </Box>
                </Paper>
                <Box display="flex" justifyContent={'end'} mt={0.5}>
                  <Typography variant="subtitle2">{formatTimestamp(memo.createdAt)}</Typography>
                </Box>
              </Box>
            )
          })}
        </Stack>
        <Stack spacing={2}>
          <TextField name="memo" multiline rows={3} placeholder="メモを入力してください" onChange={(e) => setMem(e.target.value)} value={mem} />
          <Box display="flex" justifyContent="end">
            <Button variant="contained" onClick={handleSubmit} disabled={!mem}>
              メモする
            </Button>
          </Box>
        </Stack>
      </Stack>
      <BaseDialog
        title="メモを削除しますか？"
        content={memoDeleted?.content ?? ''}
        actionText="削除する"
        open={!!memoDeleted}
        onAction={handleDelete}
        onClose={() => setMemoDeleted(undefined)}
      />
      <BaseDialog
        title="メモを通知と一緒に送付しますか？"
        content={`${memos?.find((v) => v.id === memoIdForNotification)?.content}\nを通知と一緒に送付します`}
        actionText="送付する"
        open={!!memoIdForNotification}
        onAction={handleUpdateMemoIdForNotification}
        onClose={() => setMemoIdForNotification(undefined)}
      />
    </>
  )
}
