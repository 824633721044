import { Timestamp } from '@firebase/firestore'
import { ClientDevice } from '@maru44/huntre-utils/src/models/clientDevice'
import { Device, DevicePosition } from '@maru44/huntre-utils/src/models/device'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { Accordion, AccordionSummary, Box, Button, IconButton, InputLabel, Stack, TextField, Typography } from '@mui/material'
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api'
import { createRef, useCallback, useState } from 'react'
import { toast } from 'react-hot-toast'
import { IoLocation } from 'react-icons/io5'
import { BaseDialog } from 'src/components/atoms/BaseDialog'
import { IconPopover } from 'src/components/atoms/IconPopover'
import { useClientDevice } from 'src/hooks/clientDevice/useClientDevice'
import { formatTimestamp } from 'src/utils/firebase'
import { getPositionWithUpdatedAt } from 'src/utils/position'

type Props = {
  clientId: string
  device: Device
  clientDevice: ClientDevice
}

const positionFromText = (rawPosition: string): DevicePosition | undefined => {
  if (!rawPosition) return

  const sp = rawPosition.split(',')
  if (sp.length != 2) {
    return
  }

  return {
    lat: parseFloat(sp[0]),
    lon: parseFloat(sp[1]),
  }
}

export const DevicePositionContainer = (props: Props) => {
  const { position: defaultPosition, positionUpdatedAt } = getPositionWithUpdatedAt(props.device, props.clientDevice)

  const [openSwitch, setOpenSwitch] = useState<boolean>(false)
  const [openResetPosition, setOpenResetPosition] = useState<boolean>(false)
  const [rawInputPosition, setRawInputPosition] = useState<string>('')
  // const [openInputPosition, setOpenInputPosition] = useState<boolean>(false)
  const [errorPosition, setErrorPosition] = useState<string | undefined>()
  const [position, setPosition] = useState<DevicePosition | null>(defaultPosition)

  const inputRef = createRef<HTMLInputElement>()

  const handleGetPosition = useCallback(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          setRawInputPosition(`${pos.coords.latitude},${pos.coords.longitude}`)
        },
        () => {
          toast.error('現在地の取得に失敗しました')
        }
      )
    }
  }, [setRawInputPosition])

  const { updatePosition, updatePositionUse } = useClientDevice(props.device.id, props.clientId)

  const handleUpdatePosition = useCallback(async () => {
    try {
      const position = positionFromText(rawInputPosition)
      await updatePosition(position ?? null, true)
      setPosition(position ?? null)
      toast.success('位置情報の更新しました')
    } catch (e) {
      toast.error('位置情報の更新に失敗しました')
    }
  }, [rawInputPosition, updatePosition, setPosition])

  const handleResetPosition = useCallback(async () => {
    try {
      await updatePosition(null, false)
      setPosition(null)
      toast.success('位置情報をリセットしました')
    } catch (e) {
      toast.error('位置情報のリセットに失敗しました')
    }
  }, [updatePosition, setPosition])

  const handleSwitchPositionUse = useCallback(async () => {
    await updatePositionUse(!props.clientDevice.isInputPositionUsed)
  }, [props.clientDevice.isInputPositionUsed, updatePositionUse])

  return (
    <>
      {!position && !positionUpdatedAt && <Typography>設置条件によって位置情報が取得できない場合があります。その場合以下より入力いただけます。</Typography>}
      {position && positionUpdatedAt && (
        <Typography>
          {props.clientDevice.isInputPositionUsed ? '入力' : '取得'}日時: {formatTimestamp(positionUpdatedAt)}
        </Typography>
      )}
      {position && props.clientDevice.isInputPositionUsed && <Typography>* 入力された位置情報を利用しています</Typography>}
      {props.clientDevice.position && props.device.position && (
        <Box display="flex" justifyContent="end">
          <Button variant="contained" onClick={() => setOpenSwitch(true)}>
            {props.clientDevice.isInputPositionUsed ? '取得した位置情報を利用' : '入力した位置情報を利用'}
          </Button>
        </Box>
      )}
      <Box>
        <Accordion>
          <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
            <Typography>位置情報を入力する</Typography>
          </AccordionSummary>
          <AccordionSummary>
            <Stack width="100%" spacing={4}>
              <Typography>手動でデバイスの位置情報を編集できます。</Typography>
              <Box>
                <InputLabel>位置情報</InputLabel>
                <Box display="flex" alignItems="center" width="100%" gap={2}>
                  <Box flex={1}>
                    <TextField
                      name="position"
                      onChange={(e) => {
                        e.preventDefault()
                        const v = e.currentTarget.value
                        setRawInputPosition(v)
                        if (!v || /^\d+(\.\d+)?,\d+(\.\d+)?$/.test(v)) {
                          setErrorPosition(undefined)
                        } else {
                          setErrorPosition('半角数字,半角数字 のパターン(35.3931,139.4444)で入力してください')
                        }
                      }}
                      placeholder="例) 35.3931,139.4444"
                      value={rawInputPosition}
                      inputRef={inputRef}
                      fullWidth
                      helperText={errorPosition}
                      error={!!errorPosition}
                    />
                  </Box>
                  <Box>
                    <IconPopover
                      icon={
                        <IconButton onClick={handleGetPosition}>
                          <IoLocation />
                        </IconButton>
                      }
                      sx={{ height: '60px' }}
                    >
                      現在地を取得する
                    </IconPopover>
                  </Box>
                </Box>
              </Box>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Button onClick={() => setOpenResetPosition(true)} variant="outlined" disabled={!position}>
                  位置情報をリセットする
                </Button>
                <Button onClick={handleUpdatePosition} disabled={!!errorPosition || !rawInputPosition} variant="contained">
                  位置情報を更新する
                </Button>
              </Box>
            </Stack>
          </AccordionSummary>
        </Accordion>
      </Box>
      {position && <DevicePositionMap deviceName={props.device.id} position={position} positionUpdatedAt={positionUpdatedAt} />}

      {/* 切り替え */}
      <BaseDialog
        onAction={handleSwitchPositionUse}
        open={openSwitch}
        onClose={() => setOpenSwitch(false)}
        title={props.clientDevice.isInputPositionUsed ? '取得した位置情報を利用' : '入力した位置情報を利用'}
        content={props.clientDevice.isInputPositionUsed ? 'デバイスが取得した位置情報を利用しますか？' : '手動で入力した位置情報を利用しますか？'}
        actionText="位置情報を切り替える"
        maxWidth="lg"
      />
      {/* 位置情報のリセット */}
      <BaseDialog
        title="位置情報のリセット"
        content="位置情報をリセットします"
        actionText="リセットする"
        open={openResetPosition}
        onClose={() => setOpenResetPosition(false)}
        onAction={handleResetPosition}
        maxWidth="lg"
      />
    </>
  )
}

type DevicePositionMapProps = {
  deviceName: string
  position: DevicePosition
  positionUpdatedAt: Timestamp | null
}

const DevicePositionMap = (props: DevicePositionMapProps) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: import.meta.env.VITE_FIREBASE_API_KEY ?? '',
  })

  const position: google.maps.LatLngLiteral = {
    lat: props.position.lat,
    lng: props.position.lon,
  }

  const [_, setMap] = useState<google.maps.Map | null>(null)
  const onLoad = useCallback(
    (map: google.maps.Map) => {
      map.setZoom(16)
      setMap(map)
    },
    [setMap, position]
  )
  const onUnmount = useCallback(() => {
    setMap(null)
  }, [setMap])

  if (isLoaded) {
    return (
      <GoogleMap mapContainerStyle={{ width: '100%', height: '600px' }} zoom={16} center={position} onLoad={onLoad} onUnmount={onUnmount}>
        <MarkerF position={position} label={props.deviceName} />
      </GoogleMap>
    )
  }
  return <></>
}
