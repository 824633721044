import { Box, Typography } from '@mui/material'
import { Helmet } from 'react-helmet'
import { InquiryRegisterForm } from 'src/components/organisms/inquiry/InquiryRegisterForm'
import { baseURL, isStaging, noreplyEmail, serviceName } from 'src/config'

export const InquiryRegister = () => {
  return (
    <>
      <Helmet>
        {isStaging && <meta name="robots" content="noindex" />}
        <meta charSet="utf-8" />
        <title>{serviceName} | ご利用申請</title>
        <meta property="og:type" content="product" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="description" content={`${serviceName} | ご利用申請はこちらから`} />
        <meta property="description" content={`${serviceName} | ご利用申請はこちらから`} />
        <meta property="og:url" content={`${baseURL}/inquiry/register`} />
        {/* SNS */}
        {/* TODO create icon */}
        <meta property="og:image" content={`${baseURL}/icon.png`} />
        {/* TODO end of create icon */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={serviceName} />
        <meta name="twitter:description" content={`${serviceName} | ご利用申請はこちらから`} />
        <meta name="twitter:image" content={`${baseURL}/icon.png`} />
      </Helmet>
      <>
        <Typography variant="h5">利用申請フォーム</Typography>
        <Typography mt={6}>
          ご利用を開始したい方は、下記のフォームをご入力ください。
          <br />
          申請が承認され登録が完了すると、運営 ({noreplyEmail})よりメールで通知が送信されます。その後、ログインが可能になります。
        </Typography>
        <Box mt={6}>
          {/* <iframe
            src={registerFormUrl}
            width="100%"
            height="1600px"
            // frameborder="0"
            // marginheight="0"
            // marginwidth="0"
            loading="lazy"
            style={{ border: 'none', borderRadius: '4px', backgroundColor: colors.grey[100] }}
          >
            読み込んでいます…
          </iframe> */}
          <InquiryRegisterForm />
        </Box>
      </>
    </>
  )
}
