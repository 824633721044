import { clientDeviceConverter } from '@maru44/huntre-utils/src/models/clientDevice'
import { deviceConverter, DevicePosition } from '@maru44/huntre-utils/src/models/device'
import { deviceMessageConverter } from '@maru44/huntre-utils/src/models/deviceMessage'
import { and, collection, deleteDoc, doc, getDoc, getDocs, limit, orderBy, query, serverTimestamp, Timestamp, updateDoc, where } from 'firebase/firestore'
import { useCallback } from 'react'
import { NotFoundError } from 'src/utils/error'
import { firestore } from 'src/utils/firebase'
import { useSimApplication } from '../simApplication/useSimApplication'

export const useClientDevice = (id: string, clientId: string) => {
  const { getActiveSimApplicationByDeviceId } = useSimApplication()

  const getClientDeviceWithDevice = useCallback(async () => {
    const cdRef = doc(firestore, `clients/${clientId}/clientDevices/${id}`).withConverter(clientDeviceConverter)
    const snap = await getDoc(cdRef)
    const cd = snap.data()

    if (!cd) {
      throw new NotFoundError('clientDevice', id)
    }

    const dRef = doc(firestore, `devices/${cd.deviceId}`).withConverter(deviceConverter)
    const dSnap = await getDoc(dRef)
    const d = dSnap.data()

    if (!d) {
      throw new NotFoundError('device', cd.deviceId)
    }

    const simApplication = await getActiveSimApplicationByDeviceId(cd.deviceId)

    return {
      device: d,
      clientDevice: cd,
      simApplication: simApplication,
    }
  }, [id, clientId, getActiveSimApplicationByDeviceId])

  // 登録日時以降だけにする
  const listMessages = useCallback(
    async (registeredAt: Timestamp) => {
      const q = query(
        collection(firestore, `devices/${id}/deviceMessages`),
        where('createdAt', '>=', registeredAt),
        orderBy('createdAt', 'desc'),
        limit(20)
      ).withConverter(deviceMessageConverter)

      const snap = await getDocs(q)
      return snap.docs.map((v) => v.data())
    },
    [id, clientId]
  )

  // 登録日以降 & 捕獲 & setupだけ
  const listMessagesForClient = useCallback(
    async (registeredAt: Timestamp) => {
      const q = query(
        collection(firestore, `devices/${id}/deviceMessages`),
        and(where('createdAt', '>=', registeredAt), where('wakeupCause', 'in', ['ESP_SLEEP_WAKEUP_UNDEFINED', 'ESP_SLEEP_WAKEUP_EXT0'])),
        orderBy('createdAt', 'desc'),
        limit(20)
      ).withConverter(deviceMessageConverter)

      const snap = await getDocs(q)
      return snap.docs.map((v) => v.data())
    },
    [id, clientId]
  )

  const deleteClientDevice = useCallback(async () => {
    const ref = doc(firestore, `clients/${clientId}/clientDevices/${id}`)

    await deleteDoc(ref)
  }, [id, clientId])

  const updateIsTurnedOff = useCallback(
    async (isTurnedOff: boolean) => {
      const ref = doc(firestore, `clients/${clientId}/clientDevices/${id}`).withConverter(clientDeviceConverter)
      await updateDoc(ref, { isTurnedOff: isTurnedOff, updatedAt: serverTimestamp() })
    },
    [id, clientId]
  )

  const updatePosition = useCallback(
    async (position: DevicePosition | null, isInputPositionUsed?: boolean) => {
      const ref = doc(firestore, `clients/${clientId}/clientDevices/${id}`).withConverter(clientDeviceConverter)
      if (!position) {
        await updateDoc(ref, { position, isInputPositionUsed: false, positionUpdatedAt: null, updatedAt: serverTimestamp() })
      }
      await updateDoc(ref, {
        position,
        updatedAt: serverTimestamp(),
        positionUpdatedAt: serverTimestamp(),
        ...(isInputPositionUsed !== undefined ? { isInputPositionUsed } : {}),
      })
    },
    [id, clientId]
  )

  // 入力された位置情報と取得した位置情報のどちらを使うかを変える
  const updatePositionUse = useCallback(
    async (isInputPositionUsed: boolean) => {
      const ref = doc(firestore, `clients/${clientId}/clientDevices/${id}`).withConverter(clientDeviceConverter)
      await updateDoc(ref, { isInputPositionUsed, updatedAt: serverTimestamp() })
    },
    [id, clientId]
  )

  const updateMemoIdForNotification = useCallback(
    async (clientDeviceMemoId: string | undefined) => {
      const ref = doc(firestore, `clients/${clientId}/clientDevices/${id}`).withConverter(clientDeviceConverter)
      await updateDoc(ref, { memoIdForNotification: clientDeviceMemoId ?? null, updatedAt: serverTimestamp() })
    },
    [id, clientId]
  )

  return {
    getClientDeviceWithDevice,
    listMessages,
    listMessagesForClient,
    deleteClientDevice,
    updateIsTurnedOff,
    updatePosition,
    updatePositionUse,
    updateMemoIdForNotification,
  }
}
